/**
 * Ajax form handling
 **/
define('component/ajax-form',['jquery', 'component/base'], function($, Base){
	function AjaxForm(extendSettings) {

		var defaults = {
			el          	: '[data-action="ajax"]', 	//put this attribute on forms
			required  		: '[required]',
			completeClass 	: 'complete',
			onError 		: function(formel, errorObj){
				var errorlabel,
					errormessage;

				formel.find('.error').removeClass('active');
				$.each(errorObj, function(k,v){
					if ($.type(v) === 'string'){
						v = [v];
					}
					errorlabel = formel.find('.error[for="'+ k + '"]');
					errormessage =  v.join('<br />');
					errorlabel.text(errormessage).addClass('active');
				});

				formel.find('[type="submit"]').removeAttr('disabled');
				formel.removeClass('disabled');

			    $('html, body').animate({
			        scrollTop: formel.offset().top - 90
			    }, 350);
				
				ajaxFormTracking(formel, 'ERROR : ' + errormessage);
			},
			onSuccess 		: function(formel, successMsg){
				formel.addClass('complete').removeClass('disabled');
				formel.find('.error').removeClass('active');
				formel.find('input[type="submit"]').removeAttr('disabled');
				formel.next('[data-property="success-msg"]').html(successMsg);
				
				ajaxFormTracking(formel);
			}
		};

		var extended = extendSettings ? $.extend(defaults, extendSettings) : defaults;

		Base.call(this, extended);
	}
	
	var ajaxFormTracking = function(formel, message){
		if (typeof ga == 'function' && formel.attr('data-ga-event') != 'false') {

			var gaCategory = formel.attr('data-ga-event-category') || 'Form';
			var gaAction = formel.attr('data-ga-event-action') || 'Submit';
			var gaLabel = formel.attr('data-ga-event-label') || 'Contact';
			
			if(message){
				gaLabel = gaLabel + ' -- ' + message;
			}
			
			ga('send', 'event', gaCategory, gaAction, gaLabel);
		}
	}

	AjaxForm.prototype = Object.create(Base.prototype);

	AjaxForm.prototype.action = function action() {
		var s = this.settings;

		var init = function(){
			var inputs,
				error,
				thisid;

			inputs	= $(s.el).find('input');

			inputs.removeAttr('required');
			$(s.el).each(function(){
				$('<div />',{
					'data-property' : 'success-msg',
					'class'			: 'success',
				}).insertAfter($(this));
				$(this).append('<input type="hidden" name="ajax" value="1"/>');
			});
			inputs.each(function(){
				thisid = $(this).attr('id');
				error = $('<label />',{
					'for'	: thisid,
					'class'	: 'error'
				});
				error.insertAfter($(this));
			});
			initEvents();
		};

		var initEvents = function(){
			$(document).on('submit', s.el, function(e){
				e.preventDefault;

				var thisform	= $(this),
					formData	= new FormData(thisform[0]),
					url 		= thisform.attr('action');

				if (thisform.hasClass('disabled')) {
					return;
				}

				thisform.find('[type="submit"]').attr('disabled', 'disabled');
				thisform.addClass('disabled');

				$.ajax({
					url 	: url,
					type 	: 'POST',
					data 	: formData,
					cache: false,
			        contentType: false,
			        processData: false,
					success : function(data){
						try{
							s.onSuccess(thisform, data);
						} catch(e){

						}
					},
					error 	: function(data){
						s.onError(thisform, $.parseJSON(data.responseText));
					}
				});

				return false;
			});
		};

		init();
	};

	return AjaxForm;
});
