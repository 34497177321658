// Base Class. All modules are extended off this one. It's going to use jQuery as a $,
// so we'll define what's required as seen below.
define('component/base',['jquery'], function($) {

	// Prototyping an object here. We're also saying that we can throw default 
	// settings and action into it.
	function WaxBase(extendDefaults) {
		var defaults = {
				debug : false
			};
		/**
		 * Public defaults and actions based off what's brought in, blank settings.
		 * Debug set to false; if set to true in the module defaults, errors go verbose.
		 */
		this.settings = extendDefaults ? $.extend(defaults, extendDefaults) : defaults;
		this.actionArgs;
	}

	/**
	 * Action method to initialize component - must be implemented
	 * in a subclass.
	 * 
	 * @abstract
	 */
	WaxBase.prototype.action = function action() {
		throw new Error('The action method must be implemented in a subclass');
	};

	// Adding a prototype init to all modules. Modules are expected to have an action()
	// method that will utilize this.acitonArgs to initialize a jQuery or vanilla JS
	// plugin.
	// More info on prototyping: http://addyosmani.com/resources/essentialjsdesignpatterns/book/#prototypepatternjavascript
	WaxBase.prototype.init = function init(args) {		
		// $.extend takes param 1 and adds param 2. Kinda like an array merge.
		// Settings are defaults, overridden and appended by your options.
		this.actionArgs = $.extend(this.actionArgs, args);
		// If the element in question exists...
		if($(this.settings.el).length > 0){
			// A try-catch loop for verbose errors, and so execution doesn't die on one line.
			try {
				this.action();
				return true;

			} catch(e) {
				if (this.settings.debug === true){
					console.log('There was an error with "' + this.settings.name + '". :(');
					console.log(e);
				}
				return false;
			}

		} else {
			if (this.settings.debug === true){
				console.log('"' + this.settings.name + '" couldn\'t find selector "' + this.settings.el + '".');
			}
			return false;
		}
	};

	return WaxBase;
});
