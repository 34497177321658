/**
 * Custom Selects
 * ================================
 *
 * Description:  Replaces native select elements with div elements that can be styled in CSS.
 *               Desktop browsers use the simpleselect jquery plugin while mobile browsers use
 *               native functionality. Both solutions use the same HTML markup so only one set
 *               of styles is needed.
 *
 *               A "filter" onchange event is called on selects that conform to the specified
 *               filterSelector CSS selector pattern. The filter event assumes that the value
 *               attribute of the selected option contains a valid URL and sets
 *               window.location.href to that URL.
 *
 *
 * Parameters:
 *              el               : 'select',				// Selector of select elements to customize
 *              filterSelector   : '[data-action="filter"]'	// Selector of filter select elements
 *				forceDown		 : true						// true forces selects to behave more like native. The dropdown drops down and is anchored to the bottom of the select. false is the default simpleselect functionality.
 **/

define('component/custom-selects',['jquery', 'component/base', 'mobile-detect', 'simpleselect', 'debouncedresize'], function($, Base) {

	function CustomSelects(extendSettings) {

		var defaults = {
				name           : 'Custom Selects',
				el             : 'select',
				filterSelector : '[data-action="filter"]',
				forceDown      : true
			},
			extended = extendSettings ? $.extend(defaults, extendSettings) : defaults;

		Base.call(this, extended);
	}

	CustomSelects.prototype = Object.create(Base.prototype);

	CustomSelects.prototype.action = function action() {

		var s = this.settings;

		var $select = $(s.el),
			$filter = $(s.filterSelector),

			bindFilterEvent = function bindFilterEvent() {
				$filter.on('change', function() {
					console.log('test');
					var url = $(this).find('option:selected').val();
					if( url.length > 0){
						window.location.href = url;
					}
				});
			},

			initSimpleSelect = function initSimpleSelect() {
				var $window = $(window);

				$select.simpleselect();
				bindFilterEvent();

				$window.on('debouncedresize.simpleselect', function() {
					$select.simpleselect('refreshContents');
				});
				
				if (s.forceDown == true){
					
					$(document).on('click.simpleselectmod', '.simpleselect', function(e) {

						$('.options').addClass('forceDown');
						
						var optionsContainer = $(this).find('.options');
						
						//dropdown max-height.  '10' is bottom margin.
						var optionsContainerMaxHeight =  $window.height() - optionsContainer.offset().top - 10 + $window.scrollTop();
						
						
						optionsContainer.css({
							'max-height' : optionsContainerMaxHeight
						});
					});
				}
			},

			updateMobileSelect = function updateMobileSelect($select) {
				var value = $select.val();
				var title = $select.find('option[value="' + value + '"]').html();
				$select.siblings('.mobile-select-option').html(title);
				console.log(title);
				console.log($select.siblings('.mobile-select-option'));
			},

			initMobileSelect = function initMobileSelect() {
				$select.wrap('<div class="simpleselect"></div>')
				.wrap('<div class="placeholder"></div>')
				.after('<span class="mobile-select-option"></span>')
				.css({
					'border': 'none',
					'display': 'block',
					'height': '100%',
					'left': '0',
					'margin': '0',
					'opacity': '0',
					'padding': '0',
					'position': 'absolute',
					'top': '0',
					'width': '100%'
				});

				$select.each(function() { updateMobileSelect($(this)); });
				bindFilterEvent();
				$select.on('change.MobileSelect', function() { updateMobileSelect($(this)); });
			};

		// If client is a mobile device, use native mobile select.
		if ($.browser.mobile) {
			initMobileSelect();
		} else {
			initSimpleSelect();
		}
	};

	return CustomSelects;
});

