/*
 * site.js
 * Module instantiation and site-specific functions
 *
 * 1. Require
 *		1.1. Require Config
 *		1.2. Modules List
 * 2. Site Classes
 *		2.1. Example
 *		2.2. Hamburger
 * 3. Runtime
 * 4. Helpers

/* 1. Require
 ====================================================================================== */
if (typeof jQuery === 'function') {
	define('jquery', [],function () { return jQuery; });
}

// For more information on OOHology's use of require.js, clone the project 'require-sandbox.com'
// and refer to the examples and documentation in the javascript files there.

/* 1.1. Require Config
================================================= */

requirejs.config({
	baseUrl	: '/res/js',
	paths 	: {
		'mobile-detect'   : 'vendor/detect-mobile-browsers.min',
		'simpleselect'    : 'vendor/simpleselect.min',
		'sticky-kit'      : 'vendor/sticky-kit.min',
		'photoswipe'      : 'vendor/photoswipe.min',
		'debouncedresize' : 'vendor/jquery.debouncedresize',
		'slick'           : 'vendor/slick.min',
		'waypoints'       : 'vendor/waypoints.min',
		'skrollr'         : 'vendor/skrollr.min'
	}
});

/* 1.2. Modules List
================================================= */

var initComponent = function(Module) {
	var module = new Module;
	module.init();
};

require(['component/nav'],                  initComponent);
require(['component/custom-selects'],       initComponent);

require(['component/ajax-form'], function(AjaxForm) {
    var ajaxForm = new AjaxForm({
        onSuccess: function(formel, successMsg){
        	formel.addClass('complete').removeClass('disabled');
	        formel.find('.error').remove();
	        $('p[data-property="success-msg"]').html(successMsg);

	        $('html, body').animate({
		        scrollTop: $('p[data-property="success-msg"]').offset().top - 200
		    }, 350);

	        formel.find('input, textarea, button').prop("disabled", true);

	        formel.find('.simpleselect').each(function() {
	            $('.simpleselect').simpleselect("disable");
	        });

	        $('label').remove('.error');

	        formel.fadeTo(500, 0).slideUp(500);

	        AjaxForm.ajaxFormTrackingEvent();     	
        }        
    });

    var applicationForm = new AjaxForm({
    	el: '[data-action="application-ajax"]',
    	onError : function(formel, errorObj){
			var errorlabel,
				errormessage;

				console.log(errorObj);

			formel.find('.error').removeClass('active');

			$.each(errorObj, function(k,v){
				if ($.type(v) === 'string'){
					v = [v];
				}
				errorlabel = formel.find('.error[for="'+ k + '"]');
				errormessage =  v.join('<br />');
				errorlabel.text(errormessage).addClass('active');
			});

			formel.find('[type="submit"]').removeAttr('disabled');
			formel.removeClass('disabled');

			$firstError = formel.find('.error.active').first();

		    $('html, body').animate({
		        scrollTop: $firstError.offset().top - 210
		    }, 350);
		},
        onSuccess: function(formel, redirect){
        	window.location.pathname = redirect;

	        AjaxForm.ajaxFormTrackingEvent();     	
        }        
    });

    ajaxForm.init();
    applicationForm.init();
});

require(['component/carousel'], function(Carousel) {

	var indexSlider       = new Carousel({ el : '.hero-carousel' }),
		logoSlider        = new Carousel({ el : '.logo-carousel' }),
		caseStudiesSlider = new Carousel({ el : '.case-studies-slider' }),
		caseStudiesThumbs = new Carousel({ el : '.case-studies-thumbs' });

		indexSlider.init({
			autoplaySpeed: 5000
		});

		logoSlider.init({
			arrows: true,
			autoplay: false,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 5,
			slidesToScroll: 5,
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 880,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 660,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		caseStudiesThumbs.init({
			arrows: false,
			autoplay: false,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 6,
			slidesToScroll: 1,
			asNavFor: '.case-studies-slider',
			focusOnSelect: true
		});

		caseStudiesSlider.init({
			arrows: true,
			autoplay: false,
			dots: false,
			infinite: true,
			speed: 300,
			adaptiveHeight: true,
			asNavFor: '.case-studies-thumbs'
		});
});

// require(['component/stick-in-parent'],      initComponent);
// require(['component/scroll-to'],            initComponent);
// require(['component/gallery-photoswipe'],   initComponent);


/* 2. Site Classes
 ====================================================================================== */

; var SITE = (function(core, window, document, $) {
"use strict";

	/* 2.1. Example Class
	================================================= */

	core.needARoof = function() {
		var target         = $('.need-a-roof-link'),
			flyout         = $('.need-a-roof-flyout'),
			flyoutContent  = $('.need-a-roof-flyout-content, .need-a-roof-flyout-cta'),
			waypointTarget = $('[data-waypoint="need-a-roof"]');

		target.on('click', function(e) {
			e.preventDefault();

			target.toggleClass('flyout-open');

			flyout.slideToggle({
				duration: 300,
				easeing: 'ease',
				complete: function() {
					if(flyoutContent.css('opacity') === '1') {
					 	flyoutContent.css({ opacity : 0 });
					 } else {
					 	flyoutContent.css({ opacity : 1 });
					 }
				}
			});
		});

		require(['waypoints'], function(waypoints) {
			waypointTarget.waypoint({
				handler: function(direction) {
					$('body').toggleClass('need-a-roof-tab-vis');
				},
				offset: function() {
					return -this.element.clientHeight;
				}
			});
		});

	};

	core.heroSliderParallax = function() {
		var target = $('.fixed-content');

		require(['skrollr', 'mobile-detect'], function(skrollr) {
			if(!$.browser.mobile && location.pathname === "/") {
				skrollr.init();
			}
		});
	}

	core.contactFormEvents = function(){
		var $contactForm = $('form#contact-form');
		
		var init = function(){
			var $subjectSelect = $contactForm.find('[data-router="router"]');
			$subjectSelect.on('change.contactFormEvents', function(e){
				var subject = $(this).val();
				$contactForm.attr({
					'data-ga-event' 		: true,
					'data-ga-event-category': 'Contact-Form',
					'data-ga-event-label'	: subject
				});
			});

			$subjectSelect.trigger('change.contactFormEvents');
		};

		if($contactForm.is('*')) init();
	}

	core.fileUpload = function() {
		var $upload = $('#resume_file');
		var $label = $('.resume-file');

		$upload.on('change', function(e) {
			$label.text($upload[0].files[0].name);
		});
	}

	return core;

}) (SITE || {}, window, document, jQuery);


/* 3. Runtime
 ====================================================================================== */

(function ($) {
	$( document ).ready(function() {

		SITE.needARoof();
		
		SITE.heroSliderParallax();

		SITE.contactFormEvents();

		SITE.fileUpload();

	});
}) (jQuery);


/* 4. Helpers
 ====================================================================================== */

// Object.create polyfill for legacy browsers
"function"!=typeof Object.create&&(Object.create=function(){var a=function(){};return function(b){if(1<arguments.length)throw Error("Second argument not supported");if("object"!=typeof b)throw TypeError("Argument must be an object");a.prototype=b;var c=new a;a.prototype=null;return c}}());
// Console.log polyfill for IE8 https://gist.githubusercontent.com/elijahmanor/7884984/raw/console-monkey-patch.js
(function(){for(var a,e=function(){},b="assert clear count debug dir dirxml error exception group groupCollapsed groupEnd info log markTimeline profile profileEnd table time timeEnd timeStamp trace warn".split(" "),c=b.length,d=window.console=window.console||{};c--;)a=b[c],d[a]||(d[a]=e)})();
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
Object.keys||(Object.keys=function(){var e=Object.prototype.hasOwnProperty,f=!{toString:null}.propertyIsEnumerable("toString"),c="toString toLocaleString valueOf hasOwnProperty isPrototypeOf propertyIsEnumerable constructor".split(" "),g=c.length;return function(b){if("object"!==typeof b&&("function"!==typeof b||null===b))throw new TypeError("Object.keys called on non-object");var d=[],a;for(a in b)e.call(b,a)&&d.push(a);if(f)for(a=0;a<g;a++)e.call(b,c[a])&&d.push(c[a]);return d}}());
// FormData.js
!function(n){function t(){this.fake=!0,this.boundary="--------FormData"+Math.random(),this._fields=[]}n.FormData||(t.prototype.append=function(n,t){this._fields.push([n,t])},t.prototype.toString=function(){var n=this.boundary,t="";return this._fields.forEach(function(o){if(t+="--"+n+"\r\n",o[1].name){var a=o[1];t+='Content-Disposition: form-data; name="'+o[0]+'"; filename="'+a.name+'"\r\n',t+="Content-Type: "+a.type+"\r\n\r\n",t+=a.getAsBinary()+"\r\n"}else t+='Content-Disposition: form-data; name="'+o[0]+'";\r\n\r\n',t+=o[1]+"\r\n"}),t+="--"+n+"--"},n.FormData=t)}(window);
define("site", function(){});

