/**
 * Class carousel / iosSlider
 **/
define('component/carousel',['jquery', 'component/base', 'slick'], function($, Base){

	function Carousel(extendSettings) {

		// Default class configuration values
		var defaults = {
				name       : 'Carousel',
				el         : '.carousel' //carousel element
			},
			extended = extendSettings ? $.extend(defaults, extendSettings) : defaults;

		this.actionArgs = {
				arrows: false,
				autoplay: true,
  				autoplaySpeed: 3000,
  				dots: true
			};

		// Call the Base constructor
		Base.call(this, extended);
	}

	Carousel.prototype = Object.create(Base.prototype);

	// Action to be executed
	Carousel.prototype.action = function action() {
		
		var	s      = this.settings,
			args   = this.actionArgs,
			slider = $(s.el);

			slider.slick(args);
	};

	return Carousel;
});
